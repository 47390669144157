<template lang="">
    <div v-if="loading" class="loading-overlay">
        <div class="spinner"></div>
        Loading...
    </div>
    <div class="container-fluid py-5">
        <div class="box top-box mb-3 p-4 rounded-3 bg-white d-flex align-items-center shadow">
            <h4 class="font-weight-bold">Issuance Of Transaction Statement</h4>
        </div>
        <!-- Button group -->
        <!-- <div class="d-flex justify-content-start mb-0">
          
            <input type="text" v-model="inputText" placeholder="검색어를 입력하세요" class="form-control d-inline-block w-auto ml-3">
            <button class="btn btn-primary ml-3" @click="fetchData">데이터 가져오기</button>
            <button class="btn btn-secondary ml-3" @click="saveData">데이터 저장</button>
        </div> -->
        
        <div class="justify-content-between align-items-center mb-4 bg-white shadow rounded-3 p-4 align-item-center">
            <h4 class="mb-3">Transaction Statement</h4>
            
            <!-- 새로운 폼 컨트롤 -->
            <div class="mb-1 d-flex align-items-center">
                <select class="custom-invo-input form-select mr-3" v-model="selectedDestination">
                    <option value="" disabled>출고지 선택</option>
                    <!-- 출고지 옵션을 동적으로 렌더링 -->
                    <option v-for="(cust, index) in custOptions" :key="index" :value="cust.value">{{ cust.name }}</option>
                </select>
                <div class="mr-3 custom-date-box">
                    <vue-datepicker v-model="date" :start-time="startTime" :is-24="false"></vue-datepicker>
                </div>
                <!-- <input type="date" v-model="dateRange" class="custom-invo-input form-control mr-3" /> -->
                

                <input type="text" v-model="containerNumber" placeholder="컨테이너 번호 입력" class="custom-invo-input form-control mr-3" />
                <button class="btn btn-primary" @click="fetchData">추가</button>
                <div class="ml-auto">
                    
                    <!-- PDF 생성 버튼 추가 -->
                    <button class="btn btn-success" @click="saveTransaction">요청</button>
                </div>
                
            </div>
            <div class="custom-division-line"></div>
            
            <input v-if="isListed" type="text" v-model="searchQuery" placeholder="이름 검색" class="form-control custom-invo-input" style="margin-bottom:10px" />
            <table v-if="isListed" class="table table-bordered table-hover custom-table">
                <thead class="text-center">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">컨테이너 번호</th>
                        <th scope="col">부품번호</th>
                        <th scope="col">부품이름</th>
                        <th scope="col">수량</th>
                    </tr>
                    
                </thead>
                <tbody class="text-center">
                    <tr v-for="(item, index) in tableRows" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.CONT_NO }}</td>
                        <td>{{ item.ITMNO }}</td>
                        <td>{{ item.ITM_NM }}</td>
                        <td>{{ item.BOX2_QTY }}</td>
                    </tr>
                </tbody>
            </table>
            <div v-if="!isListed" class="empty-box" @click="fetchData">
                    <h5 class="empty-text">Please enter the barcode..</h5>
            </div>

            <!-- 페이지 컨트롤 -->
            <div class="d-flex justify-content-center mt-4">
                <ul class="pagination">
                    <!-- 이전 버튼 -->
                    <li class="page-item" :class="{ 'disabled': currentPage <= 1 }">
                        <button class="page-link" @click="prevPage" :disabled="currentPage <= 1">이전</button>
                    </li>

                    <!-- 현재 페이지와 총 페이지 수 표시 -->
                    <li class="page-item disabled">
                        <span class="page-link">{{ currentPage }} / {{ totalPage }}</span>
                    </li>

                    <!-- 다음 버튼 -->
                    <li class="page-item" :class="{ 'disabled': currentPage >= totalPage }">
                        <button class="page-link" @click="nextPage" :disabled="currentPage >= totalPage">다음</button>
                    </li>
                </ul>
            </div>

        </div>
        <!-- AddPartModal 컴포넌트를 사용하는 부분 -->
    <AddPartModal v-if="isModalVisible" :products="fetchedData" @submit-products="submitProducts" @close-modal="isModalVisible = false" />
    </div>
    
</template>
<script>
import AddPartModal from '@/components/Ui/AddPartModal.vue';
import axios from 'axios';
import VueDatepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

// import bootstrap from 'bootstrap'
export default {
    name: "MainInvoice",
    components: {
        AddPartModal,
        VueDatepicker,
    },

    data() {
        return {
            startTime: { hours: 12, minutes: 0 },
            currentPage: 1, // 현재 페이지를 나타냄
            itemsPerPage: 20, // 페이지 당 항목 수
            loading: false,
            searchQuery: '',
            // inputText: '',
            selectedDestination: '', // 선택된 출고지
            dateRange: '', // 선택된 날짜 범위
            containerNumber: '', // 입력된 컨테이너 번호
            // selectedValue: null, // MGA 또는 MAL 값을 저장하기 위한 데이터 속성
            tableRows: [],
            isListed: false,
            fetchedData: [], //fetchData를 통해 불러온 데이터
            filteredData: [],
            originData: [],
            isModalVisible: false,
            resultList: [],
            date: null,
            custOptions: [
                {
                    name: 'Shinhwa',
                    value: 'P0200'
                },
                {
                    name: 'Leehwa',
                    value: 'P0807'
                },
                {
                    name: 'LOGIS ALL',
                    value: 'P0806'
                },
                {
                    name: 'CNJ',
                    value: 'P0300'
                },
                {
                    name: 'Seohan Plant 1',
                    value: 'P1903'
                },
                {
                    name: 'Seohan Plant 2',
                    value: 'P0250'
                }
            ],
            finalProduct: [],
        };
    },

    computed: {
        // 현재 페이지의 시작 인덱스
        startIndex() {
            return (this.currentPage - 1) * this.itemsPerPage;
        },
        // 현재 페이지의 끝 인덱스
        endIndex() {
            return this.startIndex + this.itemsPerPage;
        },
        // 현재 페이지에 표시할 항목
        displayedRows() {
            let filteredList = this.tableRows;
            return filteredList.slice(this.startIndex, this.endIndex);
        },
        // 총 페이지 수
        totalPage() {
            return Math.ceil(this.tableRows.length / this.itemsPerPage);
        }
    },
    methods: {
        // 다음 페이지로 이동
        nextPage() {
            if (this.currentPage < this.totalPage) {
                this.currentPage++;
            }
        },
        // 이전 페이지로 이동
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        // 데이터를 가져오는 메서드
        async fetchData() {
            this.loading = true;
            // 백엔드에 보낼 객체를 구성
            if (this.containerNumber !== '') {
                const requestData = {
                    destination: this.selectedDestination,
                    dateRange: this.dateRange,
                    containerNumber: this.containerNumber
                };

                // 백엔드 요청 및 모달 표시 로직
                try {
                    const response = await axios.get('/api/getContainerParts', {
                        params: {
                            requestData: requestData,
                        }

                    });
                    console.log(response.data);

                    if (!response.data.message) {
                        this.filteredData = [];
                        for (let item of response.data.partsList) {
                            if (!Object.prototype.hasOwnProperty.call(this.filteredData, item.ITMNO.trim())) {
                                this.filteredData[item.ITMNO.trim()] = {
                                    CONT_NO: item.CONT_NO,
                                    ITMNO: item.ITMNO.trim(),
                                    ITM_NM: item.ITM_NM,
                                    BOX2_QTY: item.BOX2_QTY,
                                    BOX_LOT: item.BOX_LOT
                                }
                            } else {
                                this.filteredData[item.ITMNO.trim()].BOX2_QTY += item.BOX2_QTY;
                            }
                        }

                        this.fetchedData = Object.values(this.filteredData);
                        this.originData = Object.values(response.data.partsList);

                        this.isModalVisible = true;
                    } else {
                        alert(response.data.message);
                    }

                    // 데이터를 받아와서 모달에 표시하는 로직을 여기에 추가하세요
                } catch (err) {
                    console.error('Data fetch error: ', err);
                    this.isModalVisible = false;
                    // 에러 처리
                }
            } else {
                alert("컨테이너 넘버를 입력해주세요!");
            }

            this.loading = false;
        },


        // AddPartModal에서 제출된 추가된 제품 리스트를 처리하는 메서드
        submitProducts(addedProducts) {
            // 여기에 추가된 제품 리스트를 처리하는 로직을 작성
            // 예: API 호출, 상태 업데이트 등
            
            for (let item of addedProducts) {
                if (!this.containsObject(item, this.tableRows)) {
                    this.tableRows.push(item);

                } else {
                    alert('이미 추가된 아이템입니다.');
                }
            }
            this.originData.forEach(data => {
                if (this.tableRows.some(item => item.ITMNO.trim() === data.ITMNO.trim() && item.CONT_NO === data.CONT_NO)) {
                    this.resultList.push(data);
                }
            });
            console.log(this.resultList);
            this.isListed = true;
        },
        containsObject(obj, list) {
            return list.some(item => {
                return Object.keys(obj).every(key => obj[key] === item[key]);
            });
        },

        // 출고 요청 데이터 저장 메서드
        async saveTransaction() {
            this.loading = true;
            if (this.date === null) {
                alert('날짜를 입력해주세요.');
            } else {
                if (this.selectedDestination === '') {
                    alert('출고지를 설정해주세요.');
                } else {
                    try {
                        const response = await axios.post('/api/saveTransaction', {
                            tableData: this.resultList, // 현재 테이블 데이터를 전송
                            date: this.date,
                            destination: this.selectedDestination,
                        });
                        if (response.data.success) {
                            alert("성공");
                            this.resultList = [];
                            this.originData = [];
                            this.tableRows = [];
                        } else {
                            if (response.data.message) {
                                alert(response.data.message);
                            } else {
                                alert("저장 실패")
                            }

                        }


                    } catch (err) {
                        console.error('출고요청 저장 오류: ', err);
                    }
                }
            }
            this.loading = false;

        },
        // PDF 생성 메서드
        // async generatePdf() {
        //     try {
        //         const response = await axios.post('http://172.20.2.204:3000/create-pdf', {
        //             tableData: this.tableRows, // 현재 테이블 데이터를 전송
        //             date: this.date,
        //             destination: this.selectedDestination,
        //         }, {
        //             responseType: 'blob', // PDF 파일을 Blob 형태로 응답받음
        //         });

        //         // Blob 데이터로 PDF 파일 생성
        //         const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        //         const fileLink = document.createElement('a');

        //         fileLink.href = fileURL;
        //         fileLink.setAttribute('download', 'transaction-statement.pdf'); // 다운로드할 파일명 설정
        //         document.body.appendChild(fileLink);

        //         fileLink.click(); // 링크를 통해 다운로드 실행
        //     } catch (err) {
        //         console.error('PDF 생성 오류: ', err);
        //     }
        // },
        goToPage(pageNum) {
            if (pageNum >= 1 && pageNum <= this.totalPages) {
                this.currentPage = pageNum;
            }
        },

    },


}
</script>
<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.custom-invo-input {
    border: 1px solid #CFD0D2;
    padding: 4px;
    margin-right: 15px;
    width: 15%;
    margin-bottom: 15px;
    color: black;
}

.custom-date-box {
    margin-right: 15px;
    margin-bottom: 15px;
}

.custom-division-line {
    border-top: 1px solid #f0f2f5;
    margin: 10px 0px;

}

.empty-box {
    text-align: center;
    border-radius: 10px;
    border: 1px solid #f0f2f5;
    min-height: 400px;
}

.empty-box:hover {
    background-color: #f0f2f5;
}

.empty-text {
    margin-top: 150px;
    color: #CFD0D2;
}
</style>